import { post } from '@/utils/request'

//获取模型列表
export function getModelList(data) {
    return post('/admin/Goods/SpecTypeList', data)
}

// 添加模型列表
export function addModelList(data) {
    return post('/admin/Goods/addSpecType', data)
}

// 模型列表详情
export function modelDetail(data) {
    return post('/admin/Goods/SpecTypeInfo', data)
}
// 模型管理编辑

export function editModelList(data) {
    return post('/admin/Goods/editSpecType', data)
}

// 模型管理删除

export function delModelList(data) {
    return post('/admin/Goods/delSpecType', data)
}

// 获取规格属性列表 回显
export function getEditModelInfo(data) {
    return post('/psi/commodity/getSpecItem', data)
}

