<template>
  <div class="commodity-model">
    <!-- 卡片 -->
    <el-card>
      <div class="menu-box">
        <h2>商品规格类型管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="4">
              <el-input placeholder="请输入内容" v-model="query.name">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="getModelList()"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="modelDialogVisible = true"
                >添加规格类型</el-button
              >
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            ref="multipleTable"
            :data="modelList"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column prop="name" label="类型名称"></el-table-column>
            <el-table-column label="类型状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1"
                  >显示</el-tag
                >
                <el-tag type="danger" v-else>隐藏</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="editModel(scope.row)"
                  >编辑类型</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="delModel(scope.row)"
                  >删除类型</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            style="margin-top: 15px"
            @current-change="pageChange"
            :pager-count="15"
            @size-change="sizeChange"
            :page-sizes="[15, 25, 35]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <div style="background-color: black;width:100px;height: 120px;">
        <img style="width:100%" src="../../../../../assets/12.png" alt=""></img>
      </div>
       -->
    </el-card>
    <!-- 新增模型对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="modelDialogVisible"
      :before-close="formClose"
    >
      <el-form ref="addModelFormRef" :rules="modelRules" :model="modelForm">
        <el-form-item label-width="100px" label="类型名称" prop="name">
          <el-input v-model="modelForm.name" placeholder="请输入类型名称" />
        </el-form-item>
        <el-form-item label-width="100px" label="排序" prop="sort">
          <el-input
            type="number"
            v-model.number="modelForm.sort"
            placeholder="请输入排序"
          />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="4"
            ><el-button size="mini" type="success" @click="addNewSpecLine"
              >+ 新增规格</el-button
            ></el-col
          >
          <el-col :span="20"><span>商品规格示例：个、杯、碗等等</span></el-col>
        </el-row>
        <el-table
          ref="specTableRef"
          :data="modelForm.spec_arr"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column prop="name" label="规格名称" width="120">
            <template slot-scope="scope">
              <el-form-item class="all">
                <el-input
                  clearable
                  class="spec-input"
                  size="mini"
                  v-model="scope.row.name"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="order" label="规格排序" width="120">
            <template slot-scope="scope">
              <el-form-item class="all">
                <el-input
                  type="number"
                  clearable
                  class="spec-input"
                  size="mini"
                  v-model.number="scope.row.order"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="属性">
            <template slot-scope="scope">
              <!-- {{scope.row.item}} -->
              <el-form-item class="inner-form-item">
                <div
                  style="display: inline-block"
                  v-for="(item, index) in scope.row.item"
                  :key="index"
                >
                  <el-input
                    style="width: 80px; margin-right: 5px"
                    clearable
                    class="spec-input"
                    size="mini"
                    v-model="item.item"
                  ></el-input>
                </div>
                <div
                  style="width: 80px; display: inline-block; text-align: center"
                >
                  <span
                    @click="addNewInput(scope.row)"
                    style="color: #409eff; cursor: pointer"
                  >
                    增加</span
                  >
                  <span
                    @click="delLastInput(scope.row)"
                    style="color: #f56c6c; cursor: pointer"
                  >
                    删除</span
                  >
                </div>
              </el-form-item>
              <!-- {{scope}} -->
              <!-- <el-form ref="innerFormRef" :model="scope.row.item">
                        <el-form-item  >
                            <el-input clearable style="width:80px"></el-input>
                        </el-form-item>
                         
                          <span @click="addNewInput" style="color:#409EFF;cursor: pointer;"> 增加</span>
                        
                    </el-form> -->
            </template>
          </el-table-column>
          <el-table-column width="110" label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="delSpec(scope.$index, scope.row)"
                >删除规格</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 认</el-button>
      </span>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
// import Breadcrumb from '@/components/breadcrumb.vue'
import {
  getModelList,
  addModelList,
  delModelList,
  getEditModelInfo,
  editModelList,
} from '@/api/psi/commoditymodel.js'
export default {
  data() {
    // 验证部门是否选择的规则
    const specOrderValidator = (rule, value, callback) => {
      //   if (!this.addStaffForm.branch_id && !this.editStaffForm.branch_id) {
      //     callback(new Error('请选择部门！'))
      //   } else {
      //     callback()
      //   }
      this.modelForm.spec_arr.forEach((ele) => {
        if (ele.order) {
          if (typeof ele.order !== 'number') {
            callback(new Error('序号必须为数字'))
          }
        } else {
          callback(new Error('请输入排序序号'))
        }
        if (!ele.name) {
          callback(new Error('请输入规格名称'))
        }
        callback()
      })
    }
    return {
      title: '新增模型',
      addOrEdit: 1,

      specTableList: [],
      modelDialogVisible: false,
      modelForm: {
        name: '',
        sort: '',
        spec_arr: [
          {
            name: '',
            order: '',

            item: [{ item: '', sort: '1' }],
          },
        ],
      },
      tableLoading: false,
      modelRules: {
        name: [
          { required: true, message: '请输入模型名称', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur',
          },
        ],
        sort: [
          { required: true, message: '请输入排序序号', trigger: 'blur' },
          { type: 'number', message: '序号必须为数字', trigger: 'blur' },
        ],
        spec_arr: [
          {
            required: true,
            validate: specOrderValidator,
          },
        ],
      },
      // 模型列表
      modelList: [],
      exp: {},
      query: {
        page_code: 1,
        page_num: 15,
        page: 1,
        name: '',
      },

      delRowId: '',
      dialogLog: {
        state: false,
      },
    }
  },
  created() {
    this.getModelList()
  },
  methods: {
    // 获取模型列表
    async getModelList() {
      this.tableLoading = true
      const { data: res } = await getModelList(this.query)
      console.log(res)
      this.exp = res.exp
      this.modelList = res.data
      this.tableLoading = false
    },
    //搜索事件
    search(e) {
      console.log('搜索信息： ' + e)
    },
    // 删除模型
    delModel(row) {
      // console.log(row)
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delModelList({ type_id: row.type_id })

          if (res.code === 1) {
            this.$message.success('删除成功')
          } else {
            this.$message.error(res.message)
          }
          this.getModelList()

          done()
        })
        .catch((_) => {})
    },
    // 编辑模型
    async editModel(row) {
      // console.log(row);

      this.title = '编辑模型'
      this.modelDialogVisible = true
      this.addOrEdit = 2
      const { data: res } = await getEditModelInfo({ type_id: row.type_id })
      this.modelForm = {
        type_id: row.type_id,
        name: res.data.name,
        sort: res.data.sort,
        status: res.data.status,
      }

      this.modelForm.spec_arr = res.data.spec_arr
      // this.modelForm.spec_arr.order = res.data.sub.order
      // this.modelForm.spec_arr.item = res.data.sub.sub
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.query.page_num = value
      this.getModelList()
    },
    // 取消新增
    cancelAE() {
      this.modelDialogVisible = false
      //   this.$nextTick(() => {
      //     this.$refs.staffTreeRef.setCheckedKeys([])
      //   })
      this.title = '新增模型'
      this.addOrEdit = 1
      this.modelForm = {
        name: '',
        sort: '',
        spec_arr: [
          {
            name: '',
            order: '',
            item: [{ item: '', sort: '1' }],
          },
        ],
      }
    },
    async confirmAE() {
      this.$refs.addModelFormRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            const { data: res } = await addModelList(this.modelForm)
            console.log(res)

            // console.log(res)
            if (res.code === 1) {
              this.$message.success('添加模型成功')
            } else {
              this.$message.error(res.message)
            }
            this.getModelList()
            this.cancelAE()
          }
          if (this.addOrEdit === 2) {
            // 编辑
            const { data: res } = await editModelList(this.modelForm)
            console.log(res)
            if (res.code === 1) {
              this.$message.success('编辑模型成功')
            } else {
              this.$message.error(res.message)
            }
            this.getModelList()
            this.cancelAE()
          }
        } else {
          this.$message.error('请正确填写表格')
        }
      })
    },
    //分页切换
    pageChange(value) {
      this.query.page_code = value
      this.getModelList()
    },
    // 查看日志
    dialogOpen() {
      this.dialogLog.state = true
    },
    // 添加新的一行
    addNewSpecLine() {
      const newLine = {
        name: '',
        order: '',
        item: [{ item: '', sort: '' }],
        // index: this.index
      }
      //   this.index++
      this.modelForm.spec_arr.push(newLine)
    },
    // 删除当前规格
    delSpec(index, row) {
      console.log(row)
      this.delRowId = row.index
      this.$confirm('确认删除？')
        .then((_) => {
          this.modelForm.spec_arr.splice(index, 1)

          done()
        })
        .catch((_) => {})
    },
    // 添加新的input框
    addNewInput(row) {
      console.log(row)
      // this.modelForm.spec_arr.item.push({
      //     item:''
      // })
      row.item.push({
        item: '',
        sort: '',
      })
    },
    delLastInput(row) {
      if (row.item.length !== 0) {
        row.item.pop()
      }
    },
    // 模型对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>

<style scoped>
.spec-input {
  width: 100px;
}
.all {
  margin-bottom: 0 !important;
}
.inner-form-item {
  margin-bottom: 0 !important;
}
</style>
